//Objeto de la pagina de metanima

const data = {
    titulo:"Soñar es crear",
    estudio:"Estudio de animación",
    parrafo:`Nuestro objetivo es proporcionar a los estudiantes un conjunto integral de recursos didácticos de alta calidad que respalden su proceso de aprendizaje y práctica en el emocionante ámbito de la animación. A través de una cuidadosa selección de contenido, desde tutoriales interactivos hasta ejercicios prácticos, aspiramos a empoderar a los estudiantes en su camino hacia la mejora de sus habilidades. Al facilitar un acceso fácil y estructurado a información relevante y actualizada, deseamos fomentar la autonomía del aprendizaje y la creatividad individual. Nuestra meta es contribuir al crecimiento constante de los estudiantes en este campo, preparándose para enfrentar desafíos y oportunidades en la industria de manera competente y segura.`,
    novedades : [
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento", // dia mes anio
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
        {
          img: 'img15.png',
          nombre: "nombre completo de la noticia",
          fecha: "Fecha de lanzamiento",
        },
    ],

//secciones
    secciones:[
        {
            // seccion se manda por el titulo
            contenido1:`Descripción: curso inmersivo en nodos de Blender para crear escenas y efectos visuales impactantes en 3D.`,
            img1:"img15.png",
            urlIframe1:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,

            contenido2:`Descripción: maximiza flujo en Blender con variedad de plugins para creación 3D dinámica.`,
            img2:"img15.png",
            urlIframe2:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        {
            // seccion se manda por el titulo
            contenido1:`Descripción: IA y diseño 3D en Blender, desde conceptos a implementación práctica avanzada.`,
            img1:"img15.png",
            urlIframe1:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
            
            contenido2:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            ipsum dolor sit amet, consectetur`,
            img2:"img15.png",
            urlIframe2:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        },
        // {
        //     // seccion se manda por el titulo
        //     contenido1:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        //     ipsum dolor sit amet, consectetur`,
        //     img1:"img15.png",
        //     urlIframe1:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
            
        //     contenido2:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        //     ipsum dolor sit amet, consectetur`,
        //     img2:"img15.png",
        //     urlIframe2:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        // },
        // {
        //     // seccion se manda por el titulo
        //     contenido1:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        //     ipsum dolor sit amet, consectetur`,
        //     img1:"img15.png",
        //     urlIframe1:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
            
        //     contenido2:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        //     ipsum dolor sit amet, consectetur`,
        //     img2:"img15.png",
        //     urlIframe2:`https://3dviewer.net/#model=https://vitplanet.com/3d/test.obj`,
        // },
    ],





};

export {data};