// objeto de pagina de queines somos

const data = {
    cisfarm:"CISFARM",
    titulo:"trae tecnología y salud a tu alcance.",

    parrafo:`Bienvenidos a Cisfarm, donde la medicina y la tecnología convergen. Ofrecemos educación de vanguardia en el campo de la farmacología profesional, integrando habilidades tecnológicas para formar expertos innovadores. Prepárate para liderar la revolución médica con conocimientos sólidos y herramientas tecnológicas avanzadas en Cisfarm.`,
    
    //nuestra historia
 
    historia:[
        {
            dia:"dia",
            mes:"mes",
            año:2020,
            parrafo:`Nació la idea de poder innovar el aprendizaje de poder brindar educación virtual para químicos farmacéuticos mediante programas de entrenamiento`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2021,
            parrafo:`El equipo de Cisfarm comenzó a crecer junto a nuestra calidad de enseñanza para ofrecer lo mejor de nosotros.`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2022,
            parrafo:`Comenzamos a implementar recursos altamente didácticos para la excelencia en tu preparación académica, y obtuvimos los primeros puestos en nuestros programas de enseñanza.`
        },
        {
            dia:"dia",
            mes:"mes",
            año:2023,
            parrafo:`Lanzamos nuestra página web junto nuestra APP para darte una mejor calidad de enseñanza generando cursos de especialidad.`
        },
    ],
    promesa:`Nos comprometemos a proporcionar herramientas altamente didácticas para su formación profesional, capacitándose para sobresalir en diversos campos y especialidades médicas. Su excelencia es nuestra prioridad en cada paso del camino.`,
    objetivo:` Aprendizaje colaborativo.
            Innovación digital.
            Excelencia académica.
            Enseñanza disruptiva.`,

    mision:[
        {item:"Desarrollar, capacitar y colaborar con programas de educación digital destacadamente didácticos e interactivos para los profesionales de la salud y público interesado en el ámbito de la ciencia, salud y la farmacología."},
        //{item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
        //{item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
        //{item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"},
    ],

    vision:`Para finalizar el 2024, ser líderes en educación digital, siendo referentes en latinoamérica para el aprendizaje significativo en el ámbito de la farmacología y la farmacoterapia.`,


    // staff

    staff:[
        {
            imagen:"sin_back.png",
            cargo:"CEO",
            nombres:"David James ",
            apellidos:"Vásquez Díaz",
            parrafo:`Se realizará una descripción de antecedentes de preparación de la persona como lo siguiente: en qué universidad estudió, nombre de la carrera, cursos, bachilleratos, maestrías, carreras, etc. `
        },
        {
            imagen:"sin_back.png",
            cargo:"COO",
            nombres:"Maria Fernanda ",
            apellidos:"Rodriguez Paredes",
            parrafo:`Se realizará una descripción de antecedentes de preparación de la persona como lo siguiente: en qué universidad estudió, nombre de la carrera, cursos, bachilleratos, maestrías, carreras, etc. `
        }
    ],


    //areas

    areas:[
        {
            area:"Departamento Plataforma y Gestión Académica",
            cards:[
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                },
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                }
            ]
        },
        {
            area:"nombre de area1",
            cards:[
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                },
                {
                    parrafo:`lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    usuarios:[
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },
                        {
                            imagenUsuario: "sin_back.png",
                            cargoUsuario: "Director",
                            nombresUsuario: "Nombres y Apellidos",
                            areaUsuario: "Area",
                        },

                    ]
                }
            ]
        }
    ]

    


};

export {data};