
const dataf={
    footer1:{
        quienes:"/Quienes",
        servicioQuienes:"/Quienes",
        misionQuienes:"/Quienes",
        visionQuienes:"/Quienes",
        recorridoQuienes:"/Quienes",
        equipoQuienes:"/Quienes",

        //academia obejto
        servicioAcademi:"/menu",
        misionAcademia:"/menu",
        visionAcademia:"/menu",
        recorridoAcademia:"/menu",
        equipoAcademia:"/menu",

        //blogs
        blogs:[
        {itemurl:"/blogs",item:"Linda"},
        {itemurl:"/blogs",item:"item1"},
        {itemurl:"/blogs",item:"item1"},
        {itemurl:"/blogs",item:"item1"},
        ],

    },
    footer2:{
        terminos:"url",
        metodologia:"url",
        politica:"url",
        directrices:"url",
        facebook:"https://www.facebook.com/profile.php?id=100064140036466 ",
        instagram:"https://www.instagram.com/cisfarm_csf/ ",
       // twitter:"https://www.facebook.com/",
        tiktok:"https://www.tiktok.com/@cisfarm_oficial ",
       // youtube:"https://www.youtube.com/"



    }
}

export{dataf};