//objeto de pagina de blogs

const data={
    noticias:[
      {
        img: 'Agentes MEDICAMENTOS NUEVOS.png',
        titulo: "MEDICAMENTOS NUEVOS",
        contenido: `La resistencia a los antimicrobianos (RAM) es un desafío 
        global que causa enfermedades prolongadas, aumenta costos médicos y 
        afecta economías. Destaca la importancia de la halogenación en la lucha 
        contra la RAM, sugiriendo que compuestos halogenados podrían rejuvenecer 
        antimicrobianos, ofreciendo mejoras en actividad y espectro, además de ser 
        viables económicamente. `,
      },
      {
        img: 'Farmacia y fraganci_FARMACOGs.png',
        titulo: "FARMACOGNOSIA",
        contenido: `A lo largo de la historia las plantas han sido importantes en 
        la fabricación de perfumes y cosméticos. A través de la investigación, se 
        identificaron más de 3,000 compuestos responsables de las propiedades olorosas, 
        con énfasis en terpenoides. La compleja composición, que incluye 110 moléculas 
        comunes, refleja la relevancia de las plantas en la bio-renovable y la innovación 
        química. Se alienta a los investigadores interdisciplinarios a profundizar en este 
        fascinante campo.`,
      },
      {
        img: 'ChatGPT.png',
        titulo: "IA APLICADO A SALUD",
        contenido: `El lanzamiento de Chat GPT en noviembre de 2022 evidenció el potencial 
        y peligro de la inteligencia artificial generativa. Construido sobre una red neuronal
         masiva, puede impulsar la investigación científica y mejorar motores de búsqueda, 
         pero también presenta riesgos como el plagio. Aunque algunos países buscan regulaciones, 
         la magnitud de la regulación para modelos de lenguajes grandes sigue siendo incierta en 
         esta revolución de la inteligencia artificial. `,
      },
      {
        img: 'El efecto_ECONOMIA.png',
        titulo: "ECONOMÍA",
        contenido: `El envejecimiento de la población en países desarrollados plantea desafíos en 
        finanzas públicas, pensiones y salud. En España, el 19,26% de la población tenía más de 65 
        años en 2019, con un aumento significativo desde 1999. El "envejecimiento de los envejecimientos" 
        también es evidente. El estudio analiza el impacto del envejecimiento en el uso de servicios de 
        cuidados intensivos y su interacción con el crecimiento de la tecnología médica, destacando factores 
        clave como la salud autopercibida y enfermedades crónicas para diseñar políticas sanitarias eficientes.`,
      },
      {
        img: 'Tecnologías .png',
        titulo: "TECNOLOGÍA",
        contenido: `La tecnología robótica y la inteligencia artificial (IA) están transformando la atención médica, 
        acelerando el descubrimiento de fármacos, automatizando procesos en la fabricación farmacéutica y mejorando la 
        administración de medicamentos. Estas innovaciones prometen revolucionar la atención médica, desde diagnósticos 
        hasta tratamientos y cuidados al paciente, aunque también plantean desafíos éticos y de privacidad.`,
      },
    ],
    posts:[
        {
            img: 'Agentes antimicrobianos halogenado(2).png',
            nombre: "MEDICAMENTOS NUEVOS",
            informacion:`Agentes antimicrobianos halogenados para combatir patógenos resistentes a los medicamentos
            La resistencia a los antimicrobianos (RAM) es un fenómeno mediante el cual microbios patógenos como bacterias, hongos, virus y parásitos pueden adaptarse y crecer en presencia de compuestos antimicrobianos a los que alguna vez fueron susceptibles. Esta resistencia provoca enfermedades prolongadas e ingresos hospitalarios, el uso de medicamentos de segunda línea más caros y fracasos terapéuticos, así también afecta negativamente a las economías sanitarias. 
            
            Adicionalmente, se considera que los halógenos son importantes en varias clases de antibióticos y andamios antimicrobianos y están presentes en aproximadamente el 25% de los medicamentos autorizados y aproximadamente el 40% de los compuestos de plomo probados activamente. En particular, los productos farmacéuticos denominados “drogas de gran éxito” suelen ser halogenados; los compuestos de esta naturaleza forman múltiples interacciones covalentes con ligandos y pueden actuar tanto como electrófilos como nucleófilos. Además, la introducción de un enlace carbono-halógeno (CX) influye de diversas formas en las actividades biológicas, como la estabilidad térmica y oxidativa de las enzimas, la capacidad de unión de ligandos y la entrega intracelular. Curiosamente, los agentes antimicrobianos halogenados contribuyen de forma significativa a los sistemas de defensa naturales de varias plantas, algas marinas, hongos, animales acuáticos y holobiontes, lo que respalda la idea de que la halogenación juega un papel importante en la actividad antimicrobiana.
            
            La halogenación de andamios antimicrobianos aporta diversas ventajas; por ejemplo, puede generar nuevos mecanismos y objetivos antimicrobianos y tiene el potencial de mejorar la actividad, minimizar la toxicidad y superar la resistencia a los medicamentos; asimismo, el concepto de andamio o hibridación molecular tiene el potencial de mejorar las afinidades por múltiples objetivos. Sin embargo, un estudio exhaustivo de agentes antimicrobianos reveló que la mayoría de los antimicrobianos halogenados sintetizados no fueron evaluados para determinar su toxicidad, lo que se considera una parte esencial del descubrimiento de fármacos. 
            En el marco de la reutilización de fármacos, hay un aumento del atractivo debido al lento ritmo de aprobación de nuevos fármacos. Según los autores del artículo, los compuestos halogenados son excelentes candidatos para la reutilización de fármacos dadas sus capacidades de amplio espectro y su afinidad por diversos objetivos biológicos. Por otro lado, el rejuvenecimiento de los antimicrobianos existentes considerados ineficaces contra los patógenos resistentes es una tendencia creciente. La halogenación ofrece un medio práctico para rejuvenecer los antimicrobianos impotentes. 
            El artículo concluye con una opinión adicional que detalla acerca de cómo la halogenación y la incorporación de átomos de halógeno en estructuras farmacológicas son estrategias factibles para combatir la resistencia a los antimicrobianos porque la halogenación es simple, económicamente viable y fácilmente accesible.
            
            Faleye OS, Boya BR, Lee JH, Choi I, Lee J. Halogenated Antimicrobial Agents to Combat Drug-Resistant Pathogens. Page C, editor. Pharmacol Rev. enero de 2024;76(1):90-141.
            
            https://pharmrev.aspetjournals.org/content/76/1/90
            `,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "Linda",
            informacion:`LINDA`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            nombre: "nombre completo de la noticia",
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
    ],
    sabiasPosts:[
        {
            img: 'img16.png',
            nombre: "Especial San valentin 14 de febrero",
            informacion:`Nos sentimos atraídos por personas con sistemas inmunitarios diferentes al nuestro y nos guiamos de su olor sin ser conscientes. La razón tiene que ver con nuestro instinto reproductivo, puesto que, la descendencia de una pareja con sistemas inmunitarios distintos, tendrá una carga genética más variada. Es evidente que nuestro cerebro ha evolucionado para facilitar al máximo posible la reproducción.
            `,
            dia:"14",
            mes:"2",
            anio:"2024",
            categoria:"1"
            
          },{
            img: 'David.png',
            nombre: "Día Internacional de Síndrome de Asperger",
            informacion:`Linda`,
            dia:"18",
            mes:"2",
            anio:"2024",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "Día Internacional de Preparación contra Epidemias",
            informacion:`El 27 de diciembre ha sido declarado por la ONU como el Día Internacional de Preparación contra Epidemias. La propuesta ha sido iniciativa de la Embajada de Vietnam ante la ONU, en virtud de las repercusiones devastadoras que tienen las enfermedades infecciosas, epidemias y pandemias en la vida humana. Asimismo tiene como objetivo concienciar sobre la importancia de la prevención y la preparación para futuras epidemias, y se busca concientizar sobre los riesgos de las enfermedades infecciosas y divulgar información sobre cómo prevenirlas y erradicarlas.`,
            dia:"27",
            mes:"12",
            anio:"2024",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "lin",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "lin",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          }
    ]

};

export {data};