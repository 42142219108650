
// Objeto de la pagina de inicio
const data = [{
  //parte de los titulos
  titulo: "Prepárate en nuestro programas de entrenamiento",
  parrafo: `Si deseas información personalizada sobre nuestro eventos, regístrate y te contactaremos con nuestros asesores.`,
  boton: "Registrarse",

  // Parte de eventos

  eventos: [
    { imagen: 'Farmacología  Básica.png' },
    { imagen: 'Farmacología cardiovascular.png' },
    { imagen: 'Concurso de Becas ENAFB 2024-I.png' },
    { imagen: 'Superintensivo ENAFB 2024 .png' },
    { imagen: 'Intensivo Residentado 2024.png' },
  ],

  //casos de exito

  casos: [
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Sandra_Ivonne Inkrñampa Gallardo.png",
      puesto: "1",
      diminutivo: "er",
      nombres: "Sandra Ivonne ",
      apellidos: "Irkñampa Gallardo",
      especialidad: "Químico Farmacéutico"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Stefany_Huaynasi Aguirre.png",
      puesto: "1",
      diminutivo: "er",
      nombres: "Stefany ",
      apellidos: "Huaynasi Aguirre",
      especialidad: "Químico Farmacéutico"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Manuel Acuña Alayo.png",
      puesto: "3",
      diminutivo: "er",
      nombres: "Manuel Antonio ",
      apellidos: "Acuña Alayo",
      especialidad: "Químico Farmacéutico"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "WILDER bolaños.png",
      puesto: "1",
      diminutivo: "er",
      nombres: "Q.F. Wilder ",
      apellidos: "Bolaños Vargas",
      especialidad: "Farmacia clínica"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Alicia Esmeralda Triviños Ancachi.png",
      puesto: "2",
      diminutivo: "er",
      nombres: "Q.F. Alicia Esmeralda ",
      apellidos: "Triviños Ancachi",
      especialidad: "Farmacia hospitalaria"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Ruth Jenny Lucas Vargas.png",
      puesto: "3",
      diminutivo: "er",
      nombres: "Q.F. Ruth Jenny ",
      apellidos: "Lucas Vargas",
      especialidad: "Farmacia hospitalaria"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Robert Anthony Dietz Maravi.png",
      puesto: "3",
      diminutivo: "er",
      nombres: "Q.F. Robert Anthony ",
      apellidos: "Dietz Maravi",
      especialidad: "Farmacia clínica"
    },
    {
      texto: `Aproximadamente más del 50% de los postulantes que han sido preparados en Academia QF han logrado alcanzar su plaza deseada. `,
      parrafo: `¡Felicitamos a cada uno de nuestros colegas por este gran logro! Somos testigos de su gran esfuerzo durante un extenso periodo de preparación. Gracias por confiar en ACADEMIA Q.F.`,
      imagen: "Adriana  Isabel Medrano Garcia.png",
      puesto: "4",
      diminutivo: "er",
      nombres: "Q.F. Adriana Isabel ",
      apellidos: "Medrado Garcia",
      especialidad: "Farmacia clínica"
    },

  ],

  //tarjetas
  //este asesor tiene una imagen con el cabello medio cortado en la parte de arriba, lo quise cambiar por otra img que si sale completo
  //pero no me lo reconoce, me sale ese mensaje de error
  bord: [{
    imagen: 'WILDER BOLAÑOS VARGAS 1.png',
    titulo: "Asesor Académico",
    nombre: "Wilder ",
    apellido: "Bolaños Vargas",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " Farmacia clínica- Residentado 2022",
  },
  {
    imagen: 'YOVANA ISABEL VIVAS TORPOCO 1.png',
    titulo: "Asesor Académico",
    nombre: "Yovana Isabel ",
    apellido: "Vivas Torpoco",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " Farmacia hospitalaria - Residentado 2020",
  },
  {
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Guido Alonso ",
    apellido: "Sardón Beltran",
    puesto: "Químico Farmacéutico",
    orden: "4 Puesto",
    lugar: " Farmacia clínica - Residentado 2019",
  },
  {
    imagen: 'Mario_Cavero Medina.png',
    titulo: "Asesor Académico",
    nombre: "Mario Roberto ",
    apellido: "Cavero Medina",
    puesto: "Químico Farmacéutico",
    orden: "5 Puesto",
    lugar: " Farmacia clínica - Residentado 2021",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Manuel Arturo ",
    apellido: "Fernandez Puycan",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " Farmacia clínica - Residentado 2019",
  },
  {
    imagen: 'Nelber_Fernandez Flores.png',
    titulo: "Asesor Académico",
    nombre: "Nelber ",
    apellido: "Fernandez Flores",
    puesto: "Químico Farmacéutico",
    orden: "6 Puesto",
    lugar: " ENAFB 2023-II",
  },
  {
    //Esta tambien que raro, descargue una imagen de mejor calidad y aun asi no me la acepta
    imagen: 'Stefany_Huaynasi Aguirre.png',
    titulo: "Asesor Académico",
    nombre: "Stefany ",
    apellido: "Huaynasi Aguirre",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " ENAFB 2021- II",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Sandra Ivonne ",
    apellido: "Irkñampa Gallardo",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " ENAFB 2022 - II",
  },
  {
    imagen: 'Paola_Zohely Fernandez.png',
    titulo: "Asesor Académico",
    nombre: "Paola Zohely ",
    apellido: "Fernandez Rojas",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " ENAFB 2018 - II",
  },
  {
    imagen: 'David_James Vásquez Díaz.png',
    titulo: "Asesor Académico",
    nombre: "David James ",
    apellido: "Vásquez Díaz",
    puesto: "Químico Farmacéutico",
    orden: "2 Puesto",
    lugar: " ENAFB 2020 - II",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Adrián ",
    apellido: "Navarro Camasi",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " Farmacia hospitalaria - Residentado 2020",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Adriana Isabel ",
    apellido: "Medrano Garcia",
    puesto: "Químico Farmacéutico",
    orden: "4 Puesto",
    lugar: " Farmacia clínica - Residentado 2023",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Arturo Yoshimar ",
    apellido: "Luque Mamani",
    puesto: "Químico Farmacéutico",
    orden: "1 Puesto",
    lugar: " Farmacia clínica - Residentado 2020",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Academico",
    nombre: "Alicia Esmeralda ",
    apellido: "Triviños Ancachi",
    puesto: "Químico Farmacéutico",
    orden: "2 Puesto",
    lugar: " Farmacia hospitalaria - Residentado 2023",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Cayani Suma ",
    apellido: "Katherine Pamela",
    puesto: "Químico Farmacéutico",
    orden: "5 Puesto",
    lugar: " Farmacia clínica - Residentado 2023",
  },
  { //AQUI POR MAS QUE LE CAMBIAMOS LAS DIMENSIONES A LA IMG SALE ERROR
    imagen: 'Guido_Alonso Saldón Beltran.png',
    titulo: "Asesor Académico",
    nombre: "Manuel Antonio ",
    apellido: "Acuña Alayo",
    puesto: "Químico Farmacéutico",
    orden: "4 Puesto",
    lugar: " ENAFB 2023 - I",
  },]
}];

export { data };