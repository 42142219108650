//objeto de paginaResidentado

const data = {
    periodo:'2024',
    inicioSuper:'12 de Diciembre',
    inicioIntesivo:'INTENSIVO',


    //valoraciones

    valoraciones:"50%",
    estudiantes:"300",
    nivel:"avanzado",
    idioma:"español",

    //campo inicial

    preguntaResidentado:"¿Que es el Residentado y por que es importarte?",
    respuestaResidentado:`El Residentado es el Programa de Segunda Especialidad de los profesionales químicos farmacéuticos, que les permite adquirir experiencia y habilidades esenciales en su campo. Su importancia radica en poder especializarte en un campo específico de la farmacia, tales como: Farmacia Hospitalaria, Farmacia Clínica y Toxicología Química y Legal.`,

    metodologia:"Metodologia de ensenanza",
    metodologiaDetalle:`El programa se desarrolla mediante una metodología semi-asincrónica y e-learning, empleando herramientas digitales en línea para brindar flexibilidad y efectividad en el proceso educativo.`,

    beneficios:[
    {
        parrafo:`Estar preparados para obtener los mejores resultados en el RESIDENTADO .`
    },
    {
        parrafo:`Lograr las mejores probabilidades de ganar una buena plaza libre remunerada para el Residentado Q.F.`
    },
    {
        parrafo:`Formar parte de una comunidad de apoyo colectivo que comparta también información y experiencias del sector.`
    },
    {
        parrafo:`Acceder a descuentos especiales en futuros cursos, capacitaciones y programas.`
    },
    {
        parrafo:`Obtener más de 100 libros digitales y material bibliográfico útil para continuar capacitándose aún terminado el programa.`
    },
    {
        parrafo:`Acceso al aula virtual las 24 horas del día.`
    },
    {
        parrafo:`Informe de resultados de los simulacros de forma detallada por cursos y temas.`
    },
    {
        parrafo:`Asesoría y acompañamiento constante antes, durante su postulación al Residentado Q.F.`
    },
    ],
    

    
    duracion:"+25 semanas",
    lecciones:"31 cursos + repasos",
    modalidad:"Sincrónico y asincrónica, de acuerdo a tu ritmo",

    politicas:`Loremmm ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
    Donec massa sapien faucibus et molestie ac feugiat. Et pharetra pharetra massa massa ultricies mi quis hendrerit. Ut aliquam purus sit amet
    luctus venenatis. Vel quam elementum pulvinar etiam non quam. Duis convallis convallis tellus id. Nunc faucibus a pellentesque sit amet porttitor.
    Nulla aliquet porttitor lacus luctus accumsan. Commodo odio aenean sed adipiscing diam donec. Rutrum tellus pellentesque eu tincidunt tortor aliquam 
    nulla. Morbi leo urna molestie at elementum eu. Et ultrices neque ornare aenean euismod elementum nisi. Leo integer malesuada nunc vel risus commodo
    viverra. Orci nulla pellentesque dignissim enim.Nisl tincidunt eget nullam non nisi est sit amet. Purus semper eget duis at tellus. Magnis dis
    parturient montes nascetur. Eget mi proin sed libero. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Vitae congue eu consequat ac felis
    donec et odio pellentesque. Sed euismod nisi porta lorem mollis aliquam ut porttitor. Id donec ultrices tincidunt arcu non. Nulla porttitor massa id
    neque aliquam vestibulum morbi blandit. Accumsan sit amet nulla facilisi morbi tempus iaculis. Amet tellus cras adipiscing enim eu turpis.Sem integer vitae justo eget
    magna fermentum iaculis eu non. At volutpat diam ut venenatis tellus in. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Eu feugiat pretium nibh
    ipsum consequat nisl vel pretium lectus. Est lorem ipsum dolor sit amet consectetur adipiscing elit. Volutpat blandit aliquam etiam erat velit scelerisque in. Tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque dignissim enim sit amet venenatis. Viverra orci sagittis eu volutpat odio. Vulputate enim nulla aliquet porttitor lacus. Amet consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Magna fringilla urna porttitor rhoncus dolor purus non enim. Ipsum suspendisse ultrices gravida dictum fusce ut. Duis at tellus at urna condimentum mattis. Feugiat nisl pretium fusce id velit ut. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae.
    Aliquet enim tortor at auctor urna nunc. Sollicitudin nibh sit amet commodo nulla. Cras pulvinar mattis nunc sed blandit libero volutpat sed. Nunc congue nisi vitae suscipit tellus mauris a. Quisque egestas diam in arcu. Pretium lectus quam id leo in vitae turpis massa sed. Placerat vestibulum lectus mauris ultrices eros in. Euismod quis viverra nibh cras pulvinar mattis. Sit amet justo donec enim diam vulputate ut. Faucibus ornare suspendisse sed nisi lacus. Ut placerat orci nulla pellentesque dignissim enim. Mi eget mauris pharetra et ultrices. Nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Tellus molestie nunc non blandit massa. Placerat orci nulla pellentesque dignissim. Elementum integer enim neque volutpat. Orci porta non pulvinar neque laoreet. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Amet volutpat consequat mauris nunc congue nisi vitae. Ut faucibus pulvinar elementum integer enim neque volutpat. Sem viverra aliquet eget sit amet tellus. Fringilla est ullamcorper eget nulla facilisi. Porttitor leo a diam sollicitudin. Aliquet enim tortor at auctor urna nunc id cursus. Neque ornare aenean euismod elementum nisi quis eleifend quam. Velit aliquet sagittis id consectetur. Congue mauris rhoncus aenean vel elit. Odio aenean sed adipiscing diam donec adipiscing. Cursus eget nunc scelerisque viverra mauris in aliquam sem. Aliquam ut porttitor leo a diam sollicitudin tempor. Ut etiam sit amet nisl purus in mollis.`,

    preguntaAcademiaResidentado:"¿Por qué estudiar en la Academia Q.F.?",
    respuestaAcademiaResidentado:` Academia Q.F ofrece un exclusivo y completo programa de preparación intensiva para el Residentado. Contamos con profesionales destacados que garantizan tus mejores resultados. Nuestra metodología semi asincrónica se adapta a tu ritmo, con acompañamiento y monitoreo. Reconocemos el esfuerzo premiando becas por buen rendimiento. Ofrecemos el mejor entrenamiento y preparación asequible y con opciones de pago. Si aspiras a ser Instructor, te recibiremos con los brazos abiertos, premiando tu éxito. En Academia Q.F., la excelencia se traduce en logros tangibles y desarrollo personal. Estudiar con nosotros es invertir en un futuro exitoso en el RESIDENTADO.`,

    requisitos:`Necesitarás acceso a un dispositivo (Celular,laptop o computadora) con conexión a internet, cumplir con los lineamientos del programa designado y ser profesional Químico Farmacéutico.`,

        //docentes

    docentes:[
        {
            imagen:"David.png",
            nombre:"Guido Alonso",
            apellidos:"Sardón Beltran",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Wilder.png",
            nombre:"Wilder",
            apellidos:"Bolaños Vargas",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Yobana.png",
            nombre:"Yovana Isabel",
            apellidos:"Torpoco Vivas",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Arturo.png",
            nombre:"Manuel Arturo",
            apellidos:"Fernandez Puycan",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Adrian.png",
            nombre:"Adrian",
            apellidos:"Navarro Camasi",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Medina.png",
            nombre:"Mario Roberto",
            apellidos:"Cavero Medina",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Adriana.png",
            nombre:"Adriana Isabel",
            apellidos:"Medrano García",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Yoshimar.png",
            nombre:"Arturo Yoshimar",
            apellidos:"Luque Mamani",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Alicia.png",
            nombre:"Alicia Esmeralda",
            apellidos:"Triviños Ancachi",
            titulo:"Quimico Farmaceutico"
        },
        {
            imagen:"Cayani.png",
            nombre:"Cayani Suma",
            apellidos:"Katherine Pamela",
            titulo:"Quimico Farmaceutico"
        },
    ],

    //contenido del curso

    contenidoCurso:[
        {nombre:"Farmacotecnia ",descripcion:"Pequena descripcion"},
        {nombre:"Biofarmacia",descripcion:"Pequena descripcion"},
        {nombre:"Farmacia Asistencial",descripcion:"Pequena descripcion"},
        {nombre:"Gestión Farmacéutica",descripcion:"Pequena descripcion"},
        {nombre:"Fisiopatología ",descripcion:"Pequena descripcion"},
        {nombre:"Bioquímica",descripcion:"Pequena descripcion"},
        {nombre:"Farmacoterapia",descripcion:"Pequena descripcion"},
        {nombre:"Atenciòn Farmacèutica",descripcion:"Pequena descripcion"},
        {nombre:"Farmacologìa",descripcion:"Pequena descripcion"},
        {nombre:"Farmacocinética",descripcion:"Pequena descripcion"},
        {nombre:"Toxicología Química Ambiental",descripcion:"Pequena descripcion"},
        {nombre:"Toxicología Química Legal",descripcion:"Pequena descripcion"},
        {nombre:"Toxicología Clínica",descripcion:"Pequena descripcion"},
        {nombre:"Farmacoepidemiología ",descripcion:"Pequena descripcion"},
        {nombre:"Farmacovigilancia ",descripcion:"Pequena descripcion"},
    ],

    //textos

    //textoFinal:"Ut enim ad minim veniam, quis nostrud exercitation ullamcov laboris nisi ut aliquip nisi ut aliquip nisi ut aliquip nisi ut aliquip",
    //resumenFinal:"Este curso cuenta con # de temas por aprender",


}




export{data};

