/**
 Lista de objetos de cursos de metanima
 */


const datam =[
    {
        titulo1:"Manejo de nodos",
        titulo2:"Curso Blender",
        parrafo:`El objetivo del curso es capacitar a los estudiantes en el uso efectivo de herramientas nodales dentro de Blender. El curso busca proporcionar una comprensión integral de los nodos, permitiendo a los participantes crear, manipular y gestionar materiales, iluminación y efectos visuales en entornos 3D. A través de lecciones prácticas y teóricas, se pretende que los estudiantes adquieran las habilidades necesarias para desarrollar escenas impresionantes y efectos visuales impactantes en Blender.`,
        video:"VIDEO-HORAIZONTAL-Curso-farmacología-cardiovascular.mp4",
    
        //valoraciones
    
        valoraciones:"0%",
        estudiantes:"0",
        nivel:"1",
        idioma:"Español Latino",
    
        //campo inicial
    
        informacion:"Soñar es crear, hazlo realidad con el 3D ",
    
        preguntaCurso:"¿Por qué estudiar Manejo de nodos en Metanima?",
        respuestaCurso:`Es ideal para principiantes en Blender. Ofrece un enfoque adaptado y amigable, con instrucción clara y accesible. El curso se centra en enseñar prácticas desde el inicio, con un aprendizaje progresivo que lleva desde lo básico a técnicas más avanzadas en el manejo de nodos. Además, proporciona un entorno de apoyo y comunidad. En resumen, Metanima brinda la base perfecta para quienes se inician en el manejo de nodos en Blender, facilitando la comprensión y el avance en este emocionante campo del diseño y la animación 3D.`,
       
        // csoto
    
        costo:"76.00",
        duracion:"3 meses",
        lecciones:"6  y 12 tópicos",
        modalidad:"híbrida y virtual",
    
        politicas:`Loremmm ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
        Donec massa sapien faucibus et molestie ac feugiat. Et pharetra pharetra massa massa ultricies mi quis hendrerit. Ut aliquam purus sit amet
        luctus venenatis. Vel quam elementum pulvinar etiam non quam. Duis convallis convallis tellus id. Nunc faucibus a pellentesque sit amet porttitor.
        Nulla aliquet porttitor lacus luctus accumsan. Commodo odio aenean sed adipiscing diam donec. Rutrum tellus pellentesque eu tincidunt tortor aliquam 
        nulla. Morbi leo urna molestie at elementum eu. Et ultrices neque ornare aenean euismod elementum nisi. Leo integer malesuada nunc vel risus commodo
        viverra. Orci nulla pellentesque dignissim enim.Nisl tincidunt eget nullam non nisi est sit amet. Purus semper eget duis at tellus. Magnis dis
        parturient montes nascetur. Eget mi proin sed libero. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Vitae congue eu consequat ac felis
        donec et odio pellentesque. Sed euismod nisi porta lorem mollis aliquam ut porttitor. Id donec ultrices tincidunt arcu non. Nulla porttitor massa id
        neque aliquam vestibulum morbi blandit. Accumsan sit amet nulla facilisi morbi tempus iaculis. Amet tellus cras adipiscing enim eu turpis.Sem integer vitae justo eget
        magna fermentum iaculis eu non. At volutpat diam ut venenatis tellus in. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Eu feugiat pretium nibh
        ipsum consequat nisl vel pretium lectus. Est lorem ipsum dolor sit amet consectetur adipiscing elit. Volutpat blandit aliquam etiam erat velit scelerisque in. Tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque dignissim enim sit amet venenatis. Viverra orci sagittis eu volutpat odio. Vulputate enim nulla aliquet porttitor lacus. Amet consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Magna fringilla urna porttitor rhoncus dolor purus non enim. Ipsum suspendisse ultrices gravida dictum fusce ut. Duis at tellus at urna condimentum mattis. Feugiat nisl pretium fusce id velit ut. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae.
        Aliquet enim tortor at auctor urna nunc. Sollicitudin nibh sit amet commodo nulla. Cras pulvinar mattis nunc sed blandit libero volutpat sed. Nunc congue nisi vitae suscipit tellus mauris a. Quisque egestas diam in arcu. Pretium lectus quam id leo in vitae turpis massa sed. Placerat vestibulum lectus mauris ultrices eros in. Euismod quis viverra nibh cras pulvinar mattis. Sit amet justo donec enim diam vulputate ut. Faucibus ornare suspendisse sed nisi lacus. Ut placerat orci nulla pellentesque dignissim enim. Mi eget mauris pharetra et ultrices. Nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Tellus molestie nunc non blandit massa. Placerat orci nulla pellentesque dignissim. Elementum integer enim neque volutpat. Orci porta non pulvinar neque laoreet. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Amet volutpat consequat mauris nunc congue nisi vitae. Ut faucibus pulvinar elementum integer enim neque volutpat. Sem viverra aliquet eget sit amet tellus. Fringilla est ullamcorper eget nulla facilisi. Porttitor leo a diam sollicitudin. Aliquet enim tortor at auctor urna nunc id cursus. Neque ornare aenean euismod elementum nisi quis eleifend quam. Velit aliquet sagittis id consectetur. Congue mauris rhoncus aenean vel elit. Odio aenean sed adipiscing diam donec adipiscing. Cursus eget nunc scelerisque viverra mauris in aliquam sem. Aliquam ut porttitor leo a diam sollicitudin tempor. Ut etiam sit amet nisl purus in mollis.`,
    
        acerca:`Se enfoca en capacitar a los estudiantes en el uso efectivo de herramientas nodales dentro del software 
        Blender. Abarca desde una introducción a la interfaz de Blender hasta la creación y manipulación de materiales, 
        iluminación y efectos visuales en entornos 3D. Incluye conceptos básicos de nodos, categorías de nodos y su funcionamiento, 
        así como el uso de nodos avanzados para texturas, control con nodos de matemáticas, iluminación en Cycles y efectos visuales 
        mediante nodos de composición. El curso culmina con un proyecto final donde los estudiantes aplican sus conocimientos en la 
        creación de una escena completa utilizando nodos.`,
    
        beneficios:[
            {item:` Asesoría académica en todos los proyectos presentes en el curso para asegurarnos de tu aprendizaje. `},
            {item:` Plataforma virtual en donde podrás visualizar las clases grabadas. `},
            {item:` Modalidad virtual, híbrido. `},
            {item:` Materiales altamente didácticos para un mejor aprendizaje. `},
            {item:` Lista de recomendaciones para tu aprendizaje. `},
        ],
        requisitos:`Para público que esté iniciando en el 3D y desee conocer el manejo de los nodos en el programa de blender. 
        Tener descargado Blender en sus últimas versiones.
        Contar con una PC un laptop apto para render y manejo del programa.
        Contar con conexión a internet.`,
    
    
            //docentes
    
        docentes:[
          {
            imagen:"sin_back.png",
            nombre:"Ray Misael",
            apellidos:"Torres Pantigoso",
            titulo:"Diseñador 3D"
          },
          {
            imagen:"sin_back.png",
            nombre:"Yordan Maul",
            apellidos:"Flores Charca",
            titulo:"Diseñador 3D"
          },
        ],
    
        //contenido del curso
    
        contenidoCurso:[
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
            {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
        ],
    
        //textos

    contenidoSecciones:[
        {
            id: 1,
            tema: "Introducción y espacio de trabajo shading",
            detalle:[
              {topico:"Introducción a la iluminación y materiales"},
              {topico:"Fundamentos de modelado 3D"},
              {topico:"Introducción a la iluminación y materiales"},
            ],
          },
          {
            id: 2,
            tema: "Conceptos básicos de nodos I",
            detalle:[
              {topico:"Introducción a los nodos en Blender"},
              {topico:"Nodos de materiales básicos"},
              // {topico:"Nombre del topico2"},
              // {topico:"Nombre del topico2"},
              // {topico:"Nombre del topico2"},
              // {topico:"Nombre del topico2"},
            ],
          },
          {
            id: 3,
            tema: "Conceptos básicos de nodos II",
            detalle:[
              {topico:"Categorías de nodos"},
              // {topico:"Nombre del topico3"},
              // {topico:"Nombre del topico3"},
              // {topico:"Nombre del topico3"},
              // {topico:"Nombre del topico3"},
              // {topico:"Nombre del topico3"},
            ],
          },
          {
            id: 4,
            tema: "Avanzando en nodos de materiales",
            detalle:[
              {topico:"Nodos avanzados de texturas"},
              {topico:"Control de nodos con Math Nodes"},
            ],
          },
          {
            id: 5,
            tema: "Iluminación y efectos visuales con nodos",
            detalle:[
              {topico:"Nodos de iIluminación en Cycles"},
              {topico:"Efectos visuales con nodos de composición"},
            ],
          },
          {
            id: 6,
            tema: "Proyecto final y revisión",
            detalle:[
              {topico:"Proyecto final"},
              {topico:"Revisión y retroalimentación"},
            ],
          },

    ]
    
    
    },
    {
      titulo1:"Manejo de plugins",
      titulo2:"Curso Blender",
      parrafo:`El objetivo del curso  es familiarizar a los estudiantes con el uso, instalación y 
      configuración efectiva de una variedad de complementos en Blender. Busca enseñar cómo optimizar el 
      flujo de trabajo en la creación 3D, desde el modelado hasta la animación, el renderizado y la personalización, 
      mediante la exploración de distintos tipos de plugins. Los estudiantes aprenderán a utilizar herramientas específicas 
      para agilizar procesos, mejorar la calidad de las producciones y expandir las capacidades creativas dentro del entorno de Blender.`,
      video:"VIDEO-HORAIZONTAL-Curso-farmacología-cardiovascular.mp4",
  
      //valoraciones
  
      valoraciones:"0%",
      estudiantes:"0",
      nivel:"1",
      idioma:"Español latino",
  
      //campo inicial
  
      informacion:"Soñar es crear, hazlo realidad con el 3D ",
  
      preguntaCurso:"¿Por qué estudiar Manejo de plugins en Metanima?",
      respuestaCurso:`El curso es ideal para principiantes en Blender. 
      Ofrece una introducción amigable y fundamentos esenciales para 
      instalar, configurar y utilizar plugins básicos. Proporciona prácticas 
      guiadas, apoyo integral y un enfoque orientado a principiantes, facilitando 
      la comprensión y sentando bases sólidas para el manejo efectivo de plugins 
      en el flujo de trabajo en 3D dentro de Blender.`,
     
      // csoto
  
      costo:"76.00",
      duracion:"",
      lecciones:"5 y 12 tópicos",
      modalidad:"Híbrida y virtual ",
  
      politicas:`Loremmm ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
      Donec massa sapien faucibus et molestie ac feugiat. Et pharetra pharetra massa massa ultricies mi quis hendrerit. Ut aliquam purus sit amet
      luctus venenatis. Vel quam elementum pulvinar etiam non quam. Duis convallis convallis tellus id. Nunc faucibus a pellentesque sit amet porttitor.
      Nulla aliquet porttitor lacus luctus accumsan. Commodo odio aenean sed adipiscing diam donec. Rutrum tellus pellentesque eu tincidunt tortor aliquam 
      nulla. Morbi leo urna molestie at elementum eu. Et ultrices neque ornare aenean euismod elementum nisi. Leo integer malesuada nunc vel risus commodo
      viverra. Orci nulla pellentesque dignissim enim.Nisl tincidunt eget nullam non nisi est sit amet. Purus semper eget duis at tellus. Magnis dis
      parturient montes nascetur. Eget mi proin sed libero. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Vitae congue eu consequat ac felis
      donec et odio pellentesque. Sed euismod nisi porta lorem mollis aliquam ut porttitor. Id donec ultrices tincidunt arcu non. Nulla porttitor massa id
      neque aliquam vestibulum morbi blandit. Accumsan sit amet nulla facilisi morbi tempus iaculis. Amet tellus cras adipiscing enim eu turpis.Sem integer vitae justo eget
      magna fermentum iaculis eu non. At volutpat diam ut venenatis tellus in. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Eu feugiat pretium nibh
      ipsum consequat nisl vel pretium lectus. Est lorem ipsum dolor sit amet consectetur adipiscing elit. Volutpat blandit aliquam etiam erat velit scelerisque in. Tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque dignissim enim sit amet venenatis. Viverra orci sagittis eu volutpat odio. Vulputate enim nulla aliquet porttitor lacus. Amet consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Magna fringilla urna porttitor rhoncus dolor purus non enim. Ipsum suspendisse ultrices gravida dictum fusce ut. Duis at tellus at urna condimentum mattis. Feugiat nisl pretium fusce id velit ut. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae.
      Aliquet enim tortor at auctor urna nunc. Sollicitudin nibh sit amet commodo nulla. Cras pulvinar mattis nunc sed blandit libero volutpat sed. Nunc congue nisi vitae suscipit tellus mauris a. Quisque egestas diam in arcu. Pretium lectus quam id leo in vitae turpis massa sed. Placerat vestibulum lectus mauris ultrices eros in. Euismod quis viverra nibh cras pulvinar mattis. Sit amet justo donec enim diam vulputate ut. Faucibus ornare suspendisse sed nisi lacus. Ut placerat orci nulla pellentesque dignissim enim. Mi eget mauris pharetra et ultrices. Nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Tellus molestie nunc non blandit massa. Placerat orci nulla pellentesque dignissim. Elementum integer enim neque volutpat. Orci porta non pulvinar neque laoreet. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Amet volutpat consequat mauris nunc congue nisi vitae. Ut faucibus pulvinar elementum integer enim neque volutpat. Sem viverra aliquet eget sit amet tellus. Fringilla est ullamcorper eget nulla facilisi. Porttitor leo a diam sollicitudin. Aliquet enim tortor at auctor urna nunc id cursus. Neque ornare aenean euismod elementum nisi quis eleifend quam. Velit aliquet sagittis id consectetur. Congue mauris rhoncus aenean vel elit. Odio aenean sed adipiscing diam donec adipiscing. Cursus eget nunc scelerisque viverra mauris in aliquam sem. Aliquam ut porttitor leo a diam sollicitudin tempor. Ut etiam sit amet nisl purus in mollis.`,
  
      acerca:`Proporciona una introducción paso a paso al mundo de los plugins, enseñando 
      cómo instalar, configurar y utilizar herramientas básicas para mejorar el flujo de 
      trabajo en diseño y animación 3D. Con instrucción clara y práctica, este curso establece 
      bases sólidas para el manejo efectivo de plugins en Blender, permitiendo a los estudiantes 
      adentrarse en esta área de manera accesible y amigable.
      Datos del contenido del curso.`,
  
      beneficios:[
          {item:` Asesoría académica en todos los proyectos presentes en el curso para asegurarnos de tu aprendizaje. `},
          {item:` Plataforma virtual en donde podrás visualizar las clases grabadas. `},
          {item:` Modalidad virtual, híbrido. `},
          {item:` Materiales altamente didácticos para un mejor aprendizaje. `},
          {item:` Lista de recomendaciones para tu aprendizaje. `},
      ],
      requisitos:`Para público que esté iniciando a hacer uso de los plugins en blender y desee conocer el manejo de los plugins. 
      Tener descargado Blender en sus últimas versiones.
      Contar con una PC un laptop apto para render y manejo del programa.
      Contar con conexión a internet.`,
  
  
          //docentes
  
      docentes:[
          {
              imagen:"sin_back.png",
              nombre:"Ray Misael Torres Pantigoso",
              apellidos:"Apellidos del docente",
              titulo:"Diseñador 3D"
          },
          {
              imagen:"sin_back.png",
              nombre:"Yordan Maul Flores Charca",
              apellidos:"Apellidos del docente",
              titulo:"Diseñador 3D"
          },
          
      ],
  
      //contenido del curso
  
      contenidoCurso:[
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
          {nombre:"Nombnre del tema",descripcion:"Pequena descripcion"},
      ],
  
      //textos

  contenidoSecciones:[
      {
          id: 1,
          tema: "Introducción a los plugins en Blender",
          detalle:[
            {topico:"Conceptos básicos de plugins"},
            {topico:"Instalación y configuración de plugins"},
            {topico:"Plugins básicos."},
          ],
        },
        {
          id: 2,
          tema: "Plugins de modelado y texturización",
          detalle:[
            {topico:"Modelado con plugins"},
          ],
        },
        {
          id: 3,
          tema: "Plugins de animación y rigging",
          detalle:[
            // {topico:"Nombre del topico3"},
            // {topico:"Nombre del topico3"},
            // {topico:"Nombre del topico3"},
            // {topico:"Nombre del topico3"},
            // {topico:"Nombre del topico3"},
            // {topico:"Nombre del topico3"},
          ],
        },
        {
          id: 4,
          tema: "Plugins de renderizado y composición",
          detalle:[
            // {topico:"Nombre del topico4"},
            // {topico:"Nombre del topico4"},
            // {topico:"Nombre del topico4"},
            // {topico:"Nombre del topico4"},
            // {topico:"Nombre del topico4"},
            // {topico:"Nombre del topico4"},
          ],
        },
        {
          id: 5,
          tema: "Desarrollo de proyecto y personalización de plugins",
          detalle:[
            // {topico:"Nombre del topico5"},
            // {topico:"Nombre del topico5"},
            // {topico:"Nombre del topico5"},
            // {topico:"Nombre del topico5"},
            // {topico:"Nombre del topico5"},
            // {topico:"Nombre del topico5"},
          ],
        },

  ]
  
  
  },
    
];

export {datam};