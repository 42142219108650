//Objeto de modificacion de Academia QF

const data = {
    titulo:"Academia Q.F.",
    parrafo:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua`,
    logo:"Academia- metálico-simple.png",

    //programas de entrenamiento
    parrafoProgramas:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut la Lorem ipsum dolor sit amet, consecteturContenido del párrafo 1`,

    //cards de informacion de los progrmas

    programas:[
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            nombre:"SUPERINTENSIVO",

        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            nombre:"SUPERINTENSIVO",

        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            nombre:"SUPERINTENSIVO",

        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            nombre:"SUPERINTENSIVO",

        },
    ],
    parrafoSimulacro:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut la Lorem ipsum dolor sit amet, consecteturContenido del párrafo 1`,

    //simulacros
    simulacros:[
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            
        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            
        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            
        },
        {
            imagen:"img1.png",
            ciclo:"ENAFB-SERUMS 2023-II",
            
        },
    ]
};

export {data};

